import "./src/app.css";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import i18n from "./src/i18n/config";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007bff"
    },
    secondary: {
      main: "#6c757d"
    }
  },
  typography: {
    fontFamily: "PTSerifRegular, Open Sans, sans-serif",
    fontSize: 13
  }
});

export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </I18nextProvider>
);

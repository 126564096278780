import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa i file di traduzione per le diverse lingue
import translationEN from '../locales/en.json';
import translationIT from '../locales/it.json';

const clientLanguage = navigator.language || navigator.userLanguage

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: clientLanguage, // Lingua predefinita
    fallbackLng: 'en', // Lingua di fallback se la traduzione non è disponibile per la lingua corrente
    debug: false, // Imposta a true per attivare la modalità di debug
    detection:{order: ['navigator']},
    resources: {
      en: {
        translation: translationEN,
      },
      it: {
        translation: translationIT,
      },
    },


  });

export default i18n;
